import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { validateOtp, sendOtp } from '@/actions/otpAction';
import Script from 'next/script';
import Captcha from 'react-google-recaptcha';
import verifyCaptchaResponse from '@/actions/reCaptchaAction';
import Modal from '@/components/ui/modal';
import Link from 'next/link';

interface OtpValidationDialogProps {
  phoneNumber: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: () => void;
}

const OtpValidationDialog: React.FC<OtpValidationDialogProps> = ({
  phoneNumber,
  isOpen,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const { toast } = useToast();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [timer, setTimer] = useState(120); // 2 minutes timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef<Captcha>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isOpen) {
      setOtp(''); // Reset the OTP when the dialog is opened
      setTimer(120); // Reset the timer to 2 minutes
      setIsResendDisabled(true); // Disable the Resend OTP button
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false); // Enable the Resend OTP button
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isOpen]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleValidateOtp = async () => {
    setIsLoading(true);
    try {
      const response = await validateOtp(phoneNumber, parseInt(otp));
      if (response) {
        onClose();
        setIsLoading(false);
        onSuccess();
      } else {
        setAttempts(attempts + 1);
        if (attempts >= 2) {
          toast({
            variant: 'destructive',
            description:
              'Invalid OTP. You have exceeded the maximum number of attempts',
          });
          onFailure();
          setIsLoading(false);
        } else {
          toast({
            variant: 'destructive',
            description: 'Invalid OTP. Please try again',
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        description: 'Failed to validate OTP. Please try again later',
      });
      setIsLoading(false);
    } 
  };

  const handleResendOtp = async () => {
    if (resendAttempts >= 2) {
      toast({
        variant: 'destructive',
        description: 'Failed to resend OTP. You have exceeded maximum number of attempts.',
      });
      onClose();
      setIsLoading(false);
      return;
    }

    try {
      await sendOtp(phoneNumber);
      toast({
        variant: 'success',
        description: 'OTP has been resent successfully.',
      });
      setResendAttempts(resendAttempts + 1);
      setTimer(120); // Reset the timer to 2 minutes
      setIsResendDisabled(true); // Disable the Resend OTP button
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false); // Enable the Resend OTP button
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } catch (error) {
      toast({
        variant: 'destructive',
        description: 'Failed to resend OTP. Please try again later.',
      });
    }
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token ?? '');
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setError(''); // Clear the error when the user starts typing
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-bold">Validate Contact Information</h2>
          <input
            type="text"
            value={otp}
            onChange={handleOtpChange}
            placeholder="Enter OTP"
            className="p-2 border border-gray-300 rounded"
          />
          <div className="text-gray-600">Didn&apos;t receive OTP? Request again in {formatTime(timer)}</div>
          {resendAttempts > 1 && (
            <div className="text-gray-600 font-bold pt-2">
              Still not received the OTP? Get your queries addressed by calling us at <Link
          href="tel:+919898987878">98989 87878</Link>
            </div>
          )}
          <div className="flex justify-center gap-4">
          <Button onClick={handleValidateOtp} disabled={isLoading}>
            {isLoading ? 'Validating ...' : 'Validate OTP'}
          </Button>
            <Button onClick={handleResendOtp} disabled={isResendDisabled}>
              Resend OTP
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OtpValidationDialog;